import { createStore, applyMiddleware  } from "redux"
import rootReducer from "./reducers/index"
import { composeWithDevTools } from 'redux-devtools-extension'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
//import { transients } from './middleware/transients'
import { timer } from './middleware/timer'
import { transients } from './middleware/transients'
import { assets } from './middleware/assets'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

//import { createTransform } from 'redux-persist';

/* const SetTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    // convert mySet to an Array.
    console.log(inboundState, 'inbound')
    return inboundState
  },
  // transform state being rehydrated
  (outboundState, key) => {
    // convert mySet back to a Set.
    console.log(outboundState, 'outbound')
    return outboundState
  },
  // define which reducers this transform gets called for.
  { whitelist: ['assets'] }
); */




const persistConfig = {
    key: 'dashboard',
    storage,
    stateReconciler: autoMergeLevel2,
    blacklist: [
        'transients',
        'loadedAssets'
    ],
   // transforms: [SetTransform]
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer,  composeWithDevTools(applyMiddleware(timer, transients, assets))) 

export default store
export const persistor = persistStore(store)