import {setActivityTimestamp, setSaveActivityTimestamp, SET_ACTIVITY_TIMESTAMP, SET_SAVE_ACTIVITY_TIMESTAMP, SET_SLEEP_MODE} from '../actions/dashboard'
import {SET_SAFE_TO_EXIT, SET_ASSETS_LOADED, SET_TRANSIENT_VARIABLE, REMOVE_TRANSIENT_VARIABLE } from '../actions/transients'
import { CREATE_DEFAULT_PROJECT_ASSETS, SET_ASSETS_STATUS_TO_SAVED, HYDRATE_ASSETS, UPDATE_ASSET_DATA, CREATE_ASSET, PIN_ASSET } from '../actions/loadedAssets'
import { SET_LAST_KEY } from '../actions/assets'
//import { SET_PROJECT_STAGE_SCROLLTOP } from '../actions/projects'

// Actions that we don't to result in a save
const saveBlacklist = [
    SET_ACTIVITY_TIMESTAMP,
    SET_SAVE_ACTIVITY_TIMESTAMP,
    SET_SLEEP_MODE,
    SET_SAFE_TO_EXIT,
    CREATE_DEFAULT_PROJECT_ASSETS,
    SET_ASSETS_STATUS_TO_SAVED,
    CREATE_ASSET,
    HYDRATE_ASSETS,
    PIN_ASSET,
    SET_ASSETS_LOADED,
    UPDATE_ASSET_DATA,
    SET_TRANSIENT_VARIABLE,
    REMOVE_TRANSIENT_VARIABLE,
    SET_LAST_KEY,
    //SET_PROJECT_STAGE_SCROLLTOP,
    'HYDRATE',
    'RESET',
    'persist/PERSIST',
    'persist/REHYDRATE',
]


// Actions that we don't want to log as an activity whatsoever
const activityBlacklist = [
    SET_ACTIVITY_TIMESTAMP,
    SET_SAVE_ACTIVITY_TIMESTAMP,
    SET_TRANSIENT_VARIABLE,
    REMOVE_TRANSIENT_VARIABLE,
    SET_SLEEP_MODE,
    SET_SAFE_TO_EXIT,
    'HYDRATE',
    'RESET',
    'persist/PERSIST',
    'persist/REHYDRATE',
]

// This decides whether to inform the system that state has changed
// when state changes, the Autosave component kicks into action and saves state to the database
// Not every action should result in a database store, which is what we define here.

// The SleepMode module also makes use of this data. When no saveable activity is detected,
// for a set amount of time then we put a cover over the page indicating that we're asleep
export const timer = store => next => action => {

    const reactivating = ( action.type === SET_SLEEP_MODE && action.payload.status === true && store.getState().dashboard.asleep === false )
    const deactivating = ( action.type === SET_SLEEP_MODE && action.payload.status === false && store.getState().dashboard.asleep === true )

    // Continue
    next(action)
    
    // Log time stamp for user activity that requires an autosave
    if( ! saveBlacklist.includes(action.type) || reactivating  || deactivating )
        store.dispatch(setSaveActivityTimestamp(Date.now()))

    // Log timestamp for user-related activity - used for things like sleepmode
    if( ! activityBlacklist.includes(action.type) )
        store.dispatch(setActivityTimestamp(Date.now()))

}