import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import isHotkey from 'is-hotkey'
import { Editable, withReact, Slate } from 'slate-react'
import { createEditor } from 'slate'
import { withHistory } from 'slate-history'
import './slateEditor.css'
import { Toolbar, BlockButton, MarkButton, CountWords } from './slateComponents'
import { setTransientVariable, removeTransientVariable } from '../../../../../../redux/actions/transients'

import { Element, Leaf, toggleMark, withHtml, withImages } from './slateFunctions'
import ScrollPositionSave from '../../../../utils/scrollPosition/ScrollPositionSave'
import UpdateProjectName from '../UpdateProjectName'
import ChangeFontSize from './ChangeFontSize'

const SlateEditor = ({ asset, initialData, dataUpdated, setTransientVariable, removeTransientVariable, pinned, readonly, showToolbar = true }) => {

	const [value, setValue] = useState(initialData)
	const renderElement     = useCallback(props => <Element {...props} />, [])
	const renderLeaf        = useCallback(props => <Leaf {...props} />, [])
	const editor            = useMemo(()        => withImages(withHtml(withHistory(withReact(createEditor())))), [])
	let tabValue            = '    ' // Four spaces in place of editor tabs. Could become adjustable.

	useEffect(() => {
		// Sends to our store
		dataUpdated(value)
	}, [value, dataUpdated])


	const handleKeyDown = event => {

		const HOTKEYS = {
			'mod+b': 'bold',
			'mod+i': 'italic',
			'mod+u': 'underline',
			'mod+`': 'code'
		}

		// Check for key combos representing our styling options, such as bold
		for (const hotkey in HOTKEYS) {
			if (isHotkey(hotkey, event)) {
				event.preventDefault()
				const mark = HOTKEYS[hotkey]
				toggleMark(editor, mark)
			}
		}


		// Check for the tab button
		if( event.keyCode === 9 ){
			event.preventDefault()
			editor.insertText(tabValue)
		}
	}


	const handleChange = (value) => {
		setValue(value)
	}


	return (
		<Slate editor={editor} value={value} onChange={handleChange} data-enable-grammarly="true">
			{
				(readonly === undefined || !readonly) ?
					<Toolbar>
						{
							showToolbar ?
							<div>
								<MarkButton format="bold" icon="fa-bold" />
								<MarkButton format="italic" icon="fa-italic" />
								<MarkButton format="underline" icon="fa-underline" />
								{/* <MarkButton format="code" icon="code" />*/}
								<BlockButton format="heading-one" icon="fa-heading" />
								<BlockButton format="block-quote" icon="fa-quote-left" />
								<BlockButton format="numbered-list" icon="fa-list-ol" />
								<BlockButton format="bulleted-list" icon="fa-list-ul" />
								<MarkButton format="highlight" icon="fa-highlighter" />
								<BlockButton format="align-left" icon="fa-align-left" />
								<BlockButton format="align-center" icon="fa-align-center" />
								<BlockButton format="align-right" icon="fa-align-right" />
								{/* <MarkButton format="dynamicField" icon="fa-asterisk" /> */}
								<CountWords />
							</div>
							:null

						}
						<div>
							<ChangeFontSize asset={asset}/>
						</div>
						
					</Toolbar>
				: null
			}
			{
				pinned !== undefined && pinned === true ?
					<div className="scratchpadWindow">
					<Editable
						className="slateEditor"
						renderElement={renderElement}
						renderLeaf={renderLeaf}
						placeholder=""
						spellCheck
						readOnly={readonly || false}
						onKeyDown={handleKeyDown}
					/>
					</div>
				: 
					<ScrollPositionSave className="scratchpadScroller">
						<UpdateProjectName />
						<Editable
							className="slateEditor"
							renderElement={renderElement}
							renderLeaf={renderLeaf}
							placeholder=""
							spellCheck
							readOnly={readonly || false}
							onKeyDown={handleKeyDown}
						/>
					</ScrollPositionSave>
			}
			
		</Slate>
	)
}


const mapDispatch = dispatch => {
	return {
		setTransientVariable: (key, value) => dispatch(setTransientVariable(key, value)),
		removeTransientVariable: (key) => dispatch(removeTransientVariable(key))
	}
}


export default connect(null, mapDispatch)(SlateEditor)


