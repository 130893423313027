export const ADD_PROJECT                 = 'ADD_PROJECT'
export const RENAME_PROJECT              = 'RENAME_PROJECT'
export const DELETE_PROJECT              = 'DELETE_PROJECT'
export const UPDATE_PROJECT_PARENT       = 'UPDATE_PROJECT_PARENT'
export const SET_PROJECT_STAGE_SCROLLTOP = 'SET_PROJECT_STAGE_SCROLLTOP'
export const SET_PROJECT_FONT_MULTIPLIER = 'SET_PROJECT_FONT_MULTIPLIER'

export const addProject = (folderKey) => {
    return { type: ADD_PROJECT, payload: { folderKey }}
}

export const deleteProject = (projectKey) => {
    return { type: DELETE_PROJECT, payload: { projectKey }}
}

export const renameProject = (projectKey, title) => {
    return { type: RENAME_PROJECT, payload: { projectKey, title }}
}

export const updateProjectParent = (folderKey, projectKey) => {
    return { type: UPDATE_PROJECT_PARENT, payload: { folderKey, projectKey }}
}

export const setProjectStageScrollTop = (projectKey, value) => {
    return { type: SET_PROJECT_STAGE_SCROLLTOP, payload: { projectKey, value }}
}

export const setProjectFontMultiplier = (projectKey, value) => {
    return { type: SET_PROJECT_FONT_MULTIPLIER, payload: { projectKey, value }}
}