export const SET_ASSETS_LOADED         = 'SET_ASSETS_LOADED'
export const SET_SAFE_TO_EXIT          = 'SET_SAFE_TO_EXIT'
export const SET_TRANSIENT_VARIABLE    = 'SET_TRANSIENT_VARIABLE'
export const REMOVE_TRANSIENT_VARIABLE = 'REMOVE_TRANSIENT_VARIABLE'

export const setAssetsLoaded = () => {
    return { type: SET_ASSETS_LOADED }
}

export const setSafeToExit = (status) => {
    return { type: SET_SAFE_TO_EXIT, payload: { status }}
}

export const setTransientVariable = (key, value) => {
    return { type: SET_TRANSIENT_VARIABLE, payload: { key, value }}
}

export const removeTransientVariable = (key) => {
    return { type: REMOVE_TRANSIENT_VARIABLE, payload: { key }}
}

