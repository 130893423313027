export const RESET   = 'RESET'
export const HYDRATE = 'HYDRATE'


export const reset = () => {
    return { type: RESET }
}

export const hydrate = (state) => {
    return { type: HYDRATE, payload: state}
}