export const SET_OPERATOR_WORKBENCH_VISIBLE = 'TOGGLE_OPERATOR_WORKBENCH_VISIBLE'
export const SET_STATION_VISIBLE            = 'TOGGLE_STATION_VISIBLE'
export const SET_STAGE_VISIBLE              = 'TOGGLE_STAGE_VISIBLE'
export const SET_ACTIVITY_TIMESTAMP         = 'SET_ACTIVITY_TIMESTAMP'
export const SET_SAVE_ACTIVITY_TIMESTAMP    = 'SET_SAVE_ACTIVITY_TIMESTAMP'
export const SET_SLEEP_MODE                 = 'SET_SLEEP_MODE'
export const SET_SLEEP_TIMEOUT              = 'SET_SLEEP_TIMEOUT'

export const setOperatorWorkbenchVisible = (status) => {
    return { type: SET_OPERATOR_WORKBENCH_VISIBLE, payload: { status } } 
}


export const setStationVisible = (status) => {
    return { type: SET_STATION_VISIBLE, payload: { status } }
}


export const setStageVisible = (status) => {
    return { type: SET_STAGE_VISIBLE, payload: { status }}
}

export const setSaveActivityTimestamp = (timestamp) => {
    return { type: SET_SAVE_ACTIVITY_TIMESTAMP, payload: { timestamp }}
}


export const setActivityTimestamp = (timestamp) => {
    return { type: SET_ACTIVITY_TIMESTAMP, payload: { timestamp }}
}

export const setSleepMode = (status) => {
    return { type: SET_SLEEP_MODE, payload: { status }}
}

export const setSleepTimeout = (minutes) => {
    return { type: SET_SLEEP_TIMEOUT , payload: { minutes }}
}
