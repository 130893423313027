import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { setProjectFontMultiplier } from '../../../../../../redux/actions/projects'

const ChangeFontSize = ({ project, asset, setProjectFontMultiplier }) => {

   

    const [multiplier, setMultiplier] = useState(project.stageFontMultiplier || 100 )
    



    useEffect(()=> {
        setProjectFontMultiplier(project.id, multiplier)
    },[multiplier])
 

    const handleDecrease = () => {
        let newNumber = multiplier - 5
        if( newNumber <= 0 )
            return 
        setMultiplier(newNumber)
    }
    

    const handleIncrease = () => {
        let newNumber = multiplier + 5
        if( newNumber >= 300 )
            return 
        setMultiplier(newNumber)
    }


    return(
        <div className="changeFontSize">
            <style>
            {`
                .slateEditor p{
                    line-height: ${((multiplier + 60) * .01).toFixed(2) }em;
                    font-size: ${multiplier}%;
                }

                .slateEditor h1{
                    line-height: ${((multiplier + 60 + 35) * .01).toFixed(2) }em;
                    font-size: ${multiplier + 35}%;
                }


                .slateEditor h2{
                    line-height: ${((multiplier + 60 + 30) * .01).toFixed(2) }em;
                    font-size: ${multiplier + 30}%;
                }

                .slateEditor h3{
                    line-height:  ${((multiplier + 60 + 25) * .01).toFixed(2) }em;
                    font-size: ${multiplier + 25}%;
                }

                .slateEditor h4{
                    line-height:  ${((multiplier + 60 + 20) * .01).toFixed(2) }em;
                    font-size: ${multiplier + 20}%;
                }

                .slateEditor ol, .slateEditor ul{
                    line-height: ${((multiplier + 60) * .01).toFixed(2) }em;
                    font-size: ${multiplier}%;
                }

                .slateEditor blockquote{
                    line-height: ${((multiplier + 60) * .01).toFixed(2) }em;
                    font-size: ${multiplier}%;
                }

                .slateEditor code{
                    line-height: ${((multiplier + 60) * .01).toFixed(2) }em;
                    font-size: ${multiplier}%;
                }

                .slateEditor pre{
                    line-height: ${((multiplier + 60) * .01).toFixed(2) }em;
                    font-size: ${multiplier}%;
                }
            `}
            </style>
            <div className="changeFontSize">
                <button onClick={handleDecrease} className={'decreaseFontSize'} > - </button> <span>/</span> <button onClick={handleIncrease} className={'increaseFontSize'} > + </button>
                <span className="multiplier">{multiplier}%</span>
            </div>
        </div>
    )
}




const mapState = state => {
    return {
        project: state.projects.items[state.loadedProjects.open]
    }
}

const mapDispatch = dispatch => {
    return {
        setProjectFontMultiplier: (projectKey, value) => dispatch(setProjectFontMultiplier(projectKey, value))
    }
}

export default connect(mapState, mapDispatch)(ChangeFontSize)