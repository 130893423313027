import React, { useEffect, useState } from 'react'
import { useParams
} from "react-router-dom"
import './privateOpenView.css'
import api from '../../services/dataEngine/apiService'
import SlateEditor from '../dashboard/sections/stage/scratchpad/Slate/SlateEditor'
import Loading from '../dashboard/utils/Loading/Loading'

// This module is designed to let anyone who has the link view a user's project data
const PrivateOpenView = ({}) => {
    let { projectCryptKey } = useParams()

    const [projectData, setProjectData] = useState([])
    const [loading, setLoading]         = useState(false)

    useEffect(()=> {
        setLoading(true)
       api.getPublicProjectAssetsByKey(projectCryptKey).then( response => {
           console.log(response.data)
            setProjectData(response.data.assets)
            setLoading(false)
        }, error => {
            console.log(error)
            setLoading(false)
        })

    },[])



    return (
        <div id="privateOpenView">
            <div className="viewerSidebar">
                <button className="logoButton" onClick={()=>{ window.location.href = 'http://localhost:3000'}}>
                    <img src="/bolt-icon-enclosed-circle-128.png" className="logo" alt="icon" />
                </button>
            </div>
            {
                loading ? 
                    <Loading />

                : <div className="viewer">
                {
                    projectData.length > 0 ?
                        <div className="documentWindow">
                            <SlateEditor  initialData={projectData[0].data} dataUpdated={()=>{}} readonly={true} pinned={true} />
                        </div>
                    : null
                }
                </div>
            }
            
        </div>
    )
}

export default PrivateOpenView