//import { SET_ACTIVE_OPERATOR_TAB } from "../actions/operator"
import {projectsDefaults, projectStructure} from '../defaults'
import { ADD_PROJECT, RENAME_PROJECT, DELETE_PROJECT, UPDATE_PROJECT_PARENT, SET_PROJECT_STAGE_SCROLLTOP, SET_PROJECT_FONT_MULTIPLIER } from '../actions/projects'


export default function(state = projectsDefaults, action) {
  switch (action.type) {
    
   
    case ADD_PROJECT: 
      let newId = state.lastKey +1
      let newProject = Object.assign({}, projectStructure,{
        id: newId,
        parentFolderKey: action.payload.folderKey
      })
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [newId]: newProject
        }),
        lastKey: newId
      })



    case RENAME_PROJECT:
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [action.payload.projectKey]: Object.assign({}, state.items[action.payload.projectKey], {
            title: action.payload.title
          })
        })
      })


      // remove the project from the object and remove any references in other projects
    case DELETE_PROJECT:
      let items = {}
      Object.keys(state.items).filter(projectKey =>  parseInt(projectKey) !== action.payload.projectKey).map( projectKey => {
        items[projectKey] = state.items[projectKey]
        return false
      })
      return Object.assign({}, state, {
        items: items
      })


    case UPDATE_PROJECT_PARENT:
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [action.payload.projectKey]: Object.assign({}, state.items[action.payload.projectKey], {
            parentFolderKey: action.payload.folderKey
          })
        })
      })
  

    case SET_PROJECT_STAGE_SCROLLTOP:
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [action.payload.projectKey]: Object.assign({}, state.items[action.payload.projectKey], {
            stageScrollTop: action.payload.value
          })
        })
      })

    case SET_PROJECT_FONT_MULTIPLIER:
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [action.payload.projectKey]: Object.assign({}, state.items[action.payload.projectKey], {
            stageFontMultiplier: action.payload.value
          })
        })
      })



    default:
      return state;
  }
}
