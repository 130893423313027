import React from 'react'
import { connect } from 'react-redux'
import './scratchpad.css'
import { useState } from 'react'
import { renameProject } from '../../../../../redux/actions/projects'
import './updateProjectName.css'

const UpdateProjectName = ({ project, renameProject }) => {
    

    const handleUpdate = (e) => {
        renameProject(project.id, e.target.value)
    }


    return(
        <div className="updateProjectName">
            <input onChange={handleUpdate} value={project.title} placeholder={'Project Title'} />
        </div>
    )
}




const mapState = state => {
    return {
        project: state.projects.items[state.loadedProjects.open]
    }
}

const mapDispatch = dispatch => {
    return {
        renameProject: (projectKey, title) => dispatch(renameProject(projectKey, title))
    }
}

export default connect(mapState, mapDispatch)(UpdateProjectName)