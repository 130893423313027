import { combineReducers } from "redux"
import dashboard from "./dashboard"
import operator from "./operator"
import station from "./station"
import stage from "./stage"
import folders from './folders'
import projects from './projects'
import loadedProjects from './loadedProjects'
import user from './user'
import transients from './transients'
import assets from './assets'
import loadedAssets from './loadedAssets'
import assetMeta from './assetMeta'


const stateSaveWhitelist = [
    'dashboard',
    'operator',
    'station',
    'stage',
    'folders',
    'projects',
    'loadedProjects',
    'assets',
    'assetMeta'
]
export const filterStateWhitelist = (state) => {
    let filtered = {}
    stateSaveWhitelist.forEach( type => {
        filtered[type] = state[type]
    })
    return filtered
}


const appReducer =  combineReducers({ dashboard, operator, station, stage, folders, projects, loadedProjects, user, transients, assets, loadedAssets, assetMeta })


const rootReducer = (state, action ) => {

    if (action.type === 'RESET') {
        state = undefined
    }

    if( action.type === 'HYDRATE'){
        state = action.payload
    }
    
    return appReducer(state, action)
}

export default rootReducer