export default (key) => {
    const env  =  process.env.NODE_ENV
    // Define these before anything else. These can't be dynamic
    const BASE_URL = {
        development: 'http://base.stormjournal.local',
        frontendDevelopment: 'http://localhost:3000',
        frontendProduction: 'https://dash.stormjournal.com',
        test: '',
        production: 'https://app.stormjournal.com'
    }
    // These can be dynamic and use value from 
    const constants = {
        BASE_URL: BASE_URL,
        API_URL: {
            development: `${BASE_URL[env]}/v1`,
            test: '',
            production: `${BASE_URL[env]}/v1`
        },
        FRONTEND_BASE_URL: {
            development: `${BASE_URL['frontendDevelopment']}`,
            production: `${BASE_URL['frontendProduction']}`
        },
        PASSWORD_RESET_URL: {
            development: `${BASE_URL[env]}/password/reset`,
            test: '',
            production: `${BASE_URL[env]}/password/reset`
        },
        REGISTER_URL: {
            development: `${BASE_URL[env]}/register`,
            test: '',
            production: `${BASE_URL[env]}/register`
        },
        CLIENT_ID: {
            development: 'WebApp',
            test: '',
            production: 'WebApp'
        }
    }
    return constants[key][env]
}