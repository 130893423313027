export const SET_FOLDER_EXPANDED             = 'SET_FOLDER_EXPANDED'
export const RENAME_FOLDER                   = 'RENAME_FOLDER'
export const ADD_FOLDER                      = 'ADD_FOLDER'
export const DELETE_FOLDER                   = 'DELETE_FOLDER'
export const DELETE_FOLDER_RECURSIVE         = 'DELETE_FOLDER_RECURSIVE'
export const DELETE_CHILD_PROJECT_REFERENCES = 'DELETE_CHILD_PROJECT_REFERENCES'
export const UPDATE_FOLDER_PARENT            = 'UPDATE_FOLDER_PARENT'


export const setFolderExpanded = (folderKey, expanded) => {
    return { type: SET_FOLDER_EXPANDED, payload: { folderKey, expanded }}
}

export const addFolder = (folderKey) => {
    return { type: ADD_FOLDER, payload: { folderKey }}
}

export const deleteFolder = (folderKey) => {
    return { type: DELETE_FOLDER, payload: { folderKey }}
}

export const deleteFolderRecursive = (folderKey) => {
    return { type: DELETE_FOLDER_RECURSIVE, payload: { folderKey }}
}

export const renameFolder = (folderKey, title) => {
    return { type: RENAME_FOLDER, payload: { folderKey, title }}
}

export const updateFolderParent = (move, to) => {
    return { type: UPDATE_FOLDER_PARENT, payload: { move, to }}
}

