import { SET_ACTIVE_OPERATOR_TAB } from "../actions/operator"
import {operatorDefaults} from '../defaults'


export default function(state = operatorDefaults, action) {
  switch (action.type) {
    
   
    case SET_ACTIVE_OPERATOR_TAB:
        return Object.assign({}, state, {
            activeTab: action.payload.activeTab
        })


    default:
      return state;
  }
}
