import React, { useState } from 'react'
import { cx, css } from 'emotion'
import { Text } from 'slate'
import { useSlate, useSelected, useFocused } from 'slate-react'
import { isBlockActive, isMarkActive, toggleMark, toggleBlock } from './slateFunctions'


export const Button = React.forwardRef(
  ({ className, active, reversed, ...props }, ref) => (
    <span
      {...props}
      ref={ref}
      className={cx(
        className,
        active ? 'active' : '',
        css`
          cursor: pointer;
          color: ${reversed
            ? active
              ? 'white'
              : '#aaa'
            : active
            ? '#53696c'
            : '#9eadb2'};
        `
      )}
    />
  )
)
/* 
export const EditorValue = React.forwardRef(
  ({ className, value, ...props }, ref) => {
    const textLines = value.document.nodes
      .map(node => node.text)
      .toArray()
      .join('\n')
    return (
      <div
        ref={ref}
        {...props}
        className={cx(
          className,
          css`
            margin: 30px -20px 0;
          `
        )}
      >
        <div
          className={css`
            font-size: 14px;
            padding: 5px 20px;
            color: #404040;
            border-top: 2px solid #eeeeee;
            background: #f8f8f8;
          `}
        >
          Slate's value as text
        </div>
        <div
          className={css`
            color: #404040;
            font: 12px monospace;
            white-space: pre-wrap;
            padding: 10px 20px;
            div {
              margin: 0 0 0.5em;
            }
          `}
        >
          {textLines}
        </div>
      </div>
    )
  }
) */

export const Icon = React.forwardRef(({ className, ...props }, ref) => (
  <span
    {...props}
    ref={ref}
    className={cx(
      'material-icons',
      className,
      css`
        font-size: 18px;
        vertical-align: text-bottom;
      `
    )}
  />
))

export const Instruction = React.forwardRef(({ className, ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    className={cx(
      className,
      css`
        white-space: pre-wrap;
        margin: 0 -20px 10px;
        padding: 10px 20px;
        font-size: 14px;
        background: #f8f8e8;
      `
    )}
  />
))

export const Menu = React.forwardRef(({ className, ...props }, ref) => (
  <div
    {...props}
    ref={ref}
    className={cx(
      className,
      css`
        & > * {
          display: inline-block;
        }

        & > div > span {
          padding-left: 8px;
          padding-right: 8px;
          
        }
        & > div > span:hover{
          background: #dce6e4;
        }
        & > div > span.wordcount:hover{
          background: inherit;
        }
        
      `
    )}
  />
))
/* 
export const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body)
}
 */


export const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
  <Menu
    {...props}
    ref={ref}
    className="scratchpadToolbar"
  />
))





// These are presented in the menu - they are our text options
export const BlockButton = ({ format, icon }) => {
	const editor = useSlate()
	return (
		<Button
			active={isBlockActive(editor, format)}
			onMouseDown={event => {
				event.preventDefault()
				toggleBlock(editor, format)
			}}
		>
			<i className={`fas ${icon}`} />
		</Button>
	)
}

export const MarkButton = ({ format, icon }) => {
	const editor = useSlate()
	return (
		<Button
			active={isMarkActive(editor, format)}
			onMouseDown={event => {
				event.preventDefault()
				toggleMark(editor, format)
			}}
		>
			<i className={`fas ${icon}`} />
		</Button>
	)
}


export const ImageElement = ({ children, element }) => {
  const selected = useSelected()
  const focused = useFocused()
  //{...attributes}
  return (
    <div >
      {children}
      <img
        src={element.url}
        className={css`
          display: block;
          max-width: 100%;
          max-height: 20em;
          box-shadow: ${selected && focused ? '0 0 0 2px blue;' : 'none'};
        `}
        alt={element.url}
      />
    </div>
  )
}




// 
export const CountWords = (props) => {

	const editor = useSlate()
	// Recursively traverse all the tree to count the words in text nodes
	let wordcount = 0
	const findText = (node) => {
		if (Text.isText(node)) {
			const regex = /(?:\r\n|\r|\n)/g;  // new line, carriage return, line feed
			const cleanString = node.text.replace(regex, ' ').trim(); // replace above characters w/ space
			const wordArray = cleanString.match(/\S+/g);  // matches words according to whitespace
			if( wordArray === null ) return 
			wordcount += wordArray.length
		}
		if (node.children !== undefined && node.children.length > 0) {
			node.children.forEach(child => {
				findText(child)
			})
		}
  }
  
	editor.children.forEach(node => findText(node))

	return (
		<span className="wordcount"> {wordcount} {wordcount === 1 ? 'word' : 'words'} </span>
	)
}


export const DynamicFieldElement = ({ children, element }) => {

  const [showModule, setShowModule] = useState(false)
  const [text, setText]             = useState(children)


  return (
    <span className="dynamicField">
        <button onClick={()=> setShowModule(!showModule)} className="editDynamicField"><i className="fas fa-asterisk"></i></button> 
        {text}
    </span>
  )
}

