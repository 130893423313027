import axios from 'axios'
import config from '../../config'
import Cookies    from 'universal-cookie'
import store, { persistor } from '../../redux/store'
import { reset } from '../../redux/actions/root'
import indexDB from './indexDBService'

const cookies = new Cookies()

export const CancelToken                 = axios.CancelToken;
export const cancelSource                = CancelToken.source()
export const cancelSearchSource          = CancelToken.source()


const getToken = (email, password) => {
    return axios.post(`${config('API_URL')}/user/token`, {
        email:     email,
        password: password,
        client_id: config('CLIENT_ID')
    })
}


const setTokenCookie = (token) => {
    cookies.set('token', token, { path: '/' })
}

const hasCookie = () => {
    return cookies.get('token') !== undefined 
}


const killTokenCookie = () => {
    cookies.remove('token')
}

const killAllMemory = () => {
    cookies.remove('token')
    // Kill persisted state
    persistor.purge()
    // Kill redux store data back to default
    store.dispatch(reset())
    // Remove assets from the local database
    indexDB.assets.clear()
}




const checkSession = () => {

    if(cookies.get('token') === undefined ){
        return new Promise((resolve, reject)=> {
            reject()
        })
    }


    return axios.post(`${config('API_URL')}/checksession`, {}, {
        headers: {
            'Accept':         'application/json',
            'Authorization':  'Bearer '+cookies.get('token').access_token,
        }//,
        //cancelToken: cancelSource.token
    })
}






const getUserAndState = () => {
    return axios.get(`${config('API_URL')}/userstate`, {
        headers: {
            'Accept':         'application/json',
            'Authorization':  'Bearer '+cookies.get('token').access_token,
        }, 
        cancelToken: cancelSource.token
    })
}





const getProjectAssets = (projectKey, types) => {
    return axios.post(`${config('API_URL')}/assets/find`, {
        project_id: projectKey,
        types:      types
    },{
        headers: {
            'Accept':         'application/json',
            'Authorization':  'Bearer '+cookies.get('token').access_token,
        }//, 
        //cancelToken: cancelSource.token
    })
}


const saveAssets = (assets) => {
    return axios.post(`${config('API_URL')}/assets`, { assets: JSON.stringify(assets) }, {
        headers: {
            'Accept':         'application/json',
            'Authorization':  'Bearer '+cookies.get('token').access_token,
        }//, Hidden so that these will continue running even if other things cancel the request source
        //cancelToken: cancelSource.token
    })
}

const removeProjectAssets = (projectKey) => {
    return axios.post(`${config('API_URL')}/assets/clear`, { project_id: projectKey }, {
        headers: {
            'Accept':         'application/json',
            'Authorization':  'Bearer '+cookies.get('token').access_token,
        }//, 
        //cancelToken: cancelSource.token
    })
}


const logEvent = (user_id, type, data = {}) => {
    return axios.post(`${config('API_URL')}/logevent`, { 
        user_id,
        type,
        data: JSON.stringify(data)
    }, {
        headers: {
            'Accept':         'application/json',
            'Authorization':  'Bearer '+cookies.get('token').access_token,
        }//, 
        //cancelToken: cancelSource.token
    })
}


const updateUserState = (state) => {
    return axios.post(`${config('API_URL')}/userstate`, { state: JSON.stringify(state) }, {
        headers: {
            'Accept':         'application/json',
            'Authorization':  'Bearer '+cookies.get('token').access_token,
        }//, 
        //cancelToken: cancelSource.token
    })
}



const searchSatellites = (projectKey, filters, canceller) => {
    return axios.post(`${config('API_URL')}/satellites/search`, { project_id: projectKey, filters }, {
        headers: {
            'Accept':         'application/json',
            'Authorization':  'Bearer '+cookies.get('token').access_token,
        }, 
        cancelToken: canceller.token
    })
}


const updateSatelliteStatus = (id, status, projectKey) => {
    return axios.post(`${config('API_URL')}/satellites/status`, {
        'id':         id,
        'status':     status,
        'project_id': projectKey
    }, {
        headers: {
            'Accept':         'application/json',
            'Authorization':  'Bearer '+cookies.get('token').access_token,
        }
    })
}


const updateSatellite = (satellite) => {
    return axios.post(`${config('API_URL')}/satellite/update`, satellite, {
        headers: {
            'Accept':         'application/json',
            'Authorization':  'Bearer '+cookies.get('token').access_token,
        }
    })
}




const removeAssetsAndChildren = (assetKey) => {
    return axios.post(`${config('API_URL')}/asset/clearwithchildren`, { assetKey: assetKey }, {
        headers: {
            'Accept':         'application/json',
            'Authorization':  'Bearer '+cookies.get('token').access_token,
        }//, 
        //cancelToken: cancelSource.token
    })
}


const deleteAssetsByParent = (parentKey) => {
    return axios.post(`${config('API_URL')}/asset/clearchildren`, { parentKey: parentKey }, {
        headers: {
            'Accept':         'application/json',
            'Authorization':  'Bearer '+cookies.get('token').access_token,
        }//, 
        //cancelToken: cancelSource.token
    })
}


const searchTerm = (searchData) => {
    return axios.post(`${config('API_URL')}/searchterm`, searchData, {
         headers: {
            'Accept':         'application/json',
            'Authorization':  'Bearer '+cookies.get('token').access_token
         }, 
         cancelToken: cancelSearchSource.token
    })
}


const generatePrivateOpenURL = (projectData) => {
    return axios.post(`${config('API_URL')}/project/permissions/generate`, projectData, {
         headers: {
            'Accept':         'application/json',
            'Authorization':  'Bearer '+cookies.get('token').access_token
         }, 
         cancelToken: cancelSearchSource.token
    })
}


const getPublicProjectAssetsByKey = (key) => {
    return axios.get(`${config('API_URL')}/project/${key}`)
}


const getUserProjectPublicKey = (projectKey) => {
    return axios.get(`${config('API_URL')}/project/permissions/${projectKey}`, {
        headers: {
            'Accept':         'application/json',
            'Authorization':  'Bearer '+cookies.get('token').access_token,
        }//, 
        //cancelToken: cancelSource.token
    })
}


const generateAPIKey = () => {
    return axios.post(`${config('API_URL')}/token/generate`, {}, {
        headers: {
            'Accept':         'application/json',
            'Authorization':  'Bearer '+cookies.get('token').access_token,
        }
    })
}



const getAPIKey = () => {
    return axios.get(`${config('API_URL')}/token`,{
        headers: {
            'Accept':         'application/json',
            'Authorization':  'Bearer '+cookies.get('token').access_token,
        }
    })
}


const publishProject = (asset) => {
    return axios.post(`${config('API_URL')}/project/publish`, asset, {
        headers: {
            'Accept':         'application/json',
            'Authorization':  'Bearer '+cookies.get('token').access_token,
        }//, 
        //cancelToken: cancelSource.token
    })
}


const getProjectPublishStatus = (project_id) => {
    return axios.get(`${config('API_URL')}/project/publishstatus/${project_id}`, {
        headers: {
            'Accept':         'application/json',
            'Authorization':  'Bearer '+cookies.get('token').access_token,
        }//, 
        //cancelToken: cancelSource.token
    })
}




export default {
    getToken,
    setTokenCookie,
    hasCookie,
    killTokenCookie,
    killAllMemory,
    updateUserState,
    logEvent,
    getUserAndState,
    getProjectAssets,
    saveAssets,
    removeProjectAssets,
    checkSession,
    removeAssetsAndChildren,
    deleteAssetsByParent,
    searchTerm,
    searchSatellites,
    updateSatelliteStatus,
    updateSatellite,
    getPublicProjectAssetsByKey,
    getUserProjectPublicKey,
    generatePrivateOpenURL,
    getAPIKey,
    generateAPIKey,
    publishProject,
    getProjectPublishStatus
}