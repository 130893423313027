//import { SET_ACTIVITY_TIMESTAMP, SET_SLEEP_MODE} from '../actions/dashboard'
import { ADD_PROJECT, DELETE_PROJECT, deleteProject} from '../actions/projects'
import { toggleProjectStatus, closeProject,  } from '../actions/loadedProjects'

import { removeProjectAssets } from '../../services/dataEngine/assetService'
import { DELETE_FOLDER_RECURSIVE, deleteFolder } from '../actions/folders'

// Here we put effects that we don't want to have happen on initial page load
export const transients = store => next => action => {

    if( action.type === DELETE_PROJECT ){
        store.dispatch(closeProject(action.payload.projectKey))
        removeProjectAssets(action.payload.projectKey)
    }
    


    // 
    if( action.type === DELETE_FOLDER_RECURSIVE ){
        let state = store.getState()
        // Recursive function to find out what folders we have to delete
        const findDescendentFolders = (key, toSearch, toRemove) => {
            //
            Object.keys(state.folders.items).map( index => {
                if(state.folders.items[index].parentFolderKey === key){
                    toSearch.push(state.folders.items[index].id)
                    toRemove.push(state.folders.items[index].id)
                }
            })
            // No longer need to search the current key
            toSearch = toSearch.filter( searchFolderKey => searchFolderKey !== key )
            //
            if( toSearch.length > 0 )
                return findDescendentFolders(toSearch[0], toSearch, toRemove)
            else
                return toRemove
        }
        // Call recursive function to find sub folders
        let foldersToRemove = findDescendentFolders(action.payload.folderKey, [action.payload.folderKey], [action.payload.folderKey])
        // Get list of projects
        let projectsToRemove =  []
        Object.keys(state.projects.items).forEach( project => {
            if( foldersToRemove.indexOf(state.projects.items[project].parentFolderKey) > -1 )
            projectsToRemove.push(state.projects.items[project].id)
        })
        // Issue redux command to remove listed projects
        projectsToRemove.forEach( projectKey => {
            store.dispatch(deleteProject(projectKey))
        })
        // Issue redux command to remove listed folders
        foldersToRemove.reverse().forEach( folderKey => {
            store.dispatch(deleteFolder(folderKey))
        })

    }



    next(action)
    
    //  If we add a project then also open it
    if( action.type === ADD_PROJECT )
        store.dispatch(toggleProjectStatus(store.getState().projects.lastKey))

    

}