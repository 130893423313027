import { SET_USER_LOGIN } from "../actions/user"

import { userDefaults } from '../defaults'

export default function(state = userDefaults, action) {
  switch (action.type) {
    
   
    case SET_USER_LOGIN:
      return Object.assign({}, state, {
        in: action.payload.user
      })



    default:
      return state;
  }
}
