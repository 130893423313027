import { 
    SET_LAST_KEY
} from "../actions/assets"
import {assetsDefaults} from '../defaults'


export default function(state = assetsDefaults, action) {


    switch (action.type) {
    
   
    case SET_LAST_KEY:
        return Object.assign({}, state, {
            lastKey: action.payload.newValue
        })


    default:
        return state;
  }
}
