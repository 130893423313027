import { TOGGLE_PROJECT_STATUS, CLOSE_PROJECT, FOCUS_PROJECT, REORDER_PROJECTS } from "../actions/loadedProjects"

import { loadedProjectDefaults } from '../defaults'

export default function(state = loadedProjectDefaults, action) {
  switch (action.type) {
    
   // At this stage there is no toggling, it will just turn on whatever project is passed in
    case TOGGLE_PROJECT_STATUS: 
      return Object.assign({}, state, {
        items: state.items.includes(action.payload.projectKey) ? [...state.items] : [...state.items, action.payload.projectKey],
        open: action.payload.projectKey
      })

    
    case FOCUS_PROJECT:
      return Object.assign({}, state, {
        open: action.payload.projectKey
      })



    case CLOSE_PROJECT:
      let pre = Object.assign({}, state, {
        items: state.items.filter( projectKey => projectKey !== action.payload.projectKey )
      })
      let post = Object.assign({}, pre, {
        open: state.open === action.payload.projectKey ? pre.items.slice(-1)[0] === undefined ? null : pre.items.slice(-1)[0] : state.open
      })
      return post
      

    case REORDER_PROJECTS:
      let current = [...state.items]
      let pasteIndex = action.payload.specs.hoverIndex// + point
      current.splice( pasteIndex, 0, current.splice(action.payload.specs.dragIndex, 1)[0])
      return Object.assign({}, state, {
        items: current
      })




    default:
      return state;
  }
}
