export const TOGGLE_PROJECT_STATUS = 'TOGGLE_PROJECT_STATUS'
export const CLOSE_PROJECT         = 'CLOSE_PROJECT'
export const FOCUS_PROJECT         = 'FOCUS_PROJECT'
export const REORDER_PROJECTS      = 'REORDER_PROJECTS'


export const toggleProjectStatus = (projectKey) => {
    return { type: TOGGLE_PROJECT_STATUS, payload: { projectKey }}
}

export const closeProject = (projectKey) => {
    return { type: CLOSE_PROJECT, payload: { projectKey }}
}

export const focusProject = (projectKey) => {
    return { type: FOCUS_PROJECT, payload: { projectKey }}
}

export const reorderProjects = (specs) => {
    return { type: REORDER_PROJECTS, payload: { specs } }
}


