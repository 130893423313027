import api from './apiService'
import indexDB from './indexDBService'
import store from '../../redux/store'

import { createAsset } from '../../redux/actions/loadedAssets'
import { setLastKey } from '../../redux/actions/assets'


//console.log('This script handles all coordination between the data sources and the frontend')
//console.log('establishing connections to webworker, websocket and the ai via https')


export const requestAssets = (projectKey, types) => {
    return new Promise((resolve, reject) => {
        // Try finding the data in the local storage first
        if( projectKey === undefined || projectKey === null )
            return reject('No Project Key')
  
        indexDB.assets.where({projectKey: projectKey }).and((item) => types.includes(item.type)).toArray().then( response => {
            if( response.length > 0 ){
                resolve(response)
            } else {
                // Otherwise go to the main database
                api.getProjectAssets(projectKey, types).then( response => {
                    // And save them to the db store before sending back
                    response.data.length > 0 && response.data.forEach( asset => {
                        indexDB.assets.put(asset)
                    })
                    resolve(response.data) 
                })
            }
        })
    })
}




export const requestAssetByKey = (assetKey) => {
    return new Promise((resolve, reject) => {
        // Try finding the data in the local storage first
        if( assetKey === undefined || assetKey === null )
            return reject('No Project Key')
  
        indexDB.assets.where({assetKey: assetKey }).then( response => {
            if( response.length > 0 ){
                resolve(response)
            } else {
                // Otherwise go to the main database
                api.getAssetByKey(assetKey).then( response => {
                    // And save them to the db store before sending back
                    response.data.length > 0 && response.data.forEach( asset => {
                        indexDB.assets.put(asset)
                    })
                    resolve(response.data) 
                })
            }
        })
    })
}



export const requestAssetsBypassCache = (projectKey, types) => {
    return new Promise((resolve, reject) => {
        // Try finding the data in the local storage first
        if( projectKey === undefined || projectKey === null )
            return reject('No Project Key')
  
         api.getProjectAssets(projectKey, types).then( response => {
            // And save them to the db store before sending back
            response.data.length > 0 && response.data.forEach( asset => {
                indexDB.assets.put(asset)
            })
            resolve(response.data) 
        })
    })
}
 





// Expects an array of objects that are ready to save
export const saveAssets = (assets) => {

    // Send to local indexdb storage
    Promise.all(assets.map( asset => {

        //console.log('adding asset to index db', asset)
        return new Promise( (resolve, reject) => {
            //console.log(asset)
            //return reject('TEst')
            if( asset.assetKey === undefined )
                return reject({ status: 'error', asset, message: 'Asset Key doesn\'t exist'})

            indexDB.assets.where({ assetKey: asset.assetKey, projectKey: asset.projectKey }).first( oldAsset => {
                // If the asset doesn't already exist create it, 
                if( oldAsset === undefined ){
                    indexDB.assets.put(Object.assign({}, asset, { status: 'saved' })).then(id => {
                        return resolve(indexDB.assets.get(id))
                    }).catch( error => {
                        return reject(error)
                    })
                } else {
                    // Otherwise update the existing one.
                    indexDB.assets.update(oldAsset.localKey, { data: asset.data, status: 'saved' }).then( updated => {
                        return resolve(updated)
                    }).catch( error => {
                        return reject(error)
                    })
                }
            })
        })
    } )).then( response => {
        //console.log(response, 'responses from local save')
    }).catch (err => {
        console.log("Error: ", (err.stack || err))
    })

    // Send to database if in Online Mode
    return api.saveAssets(assets)

}



// TODO could be a duplicate of the saveAsset function without the call to the server
// perhaps that function could just use this one
export const saveToCache = (asset) => {
    return new Promise( (resolve, reject) => {

        if( asset.assetKey === undefined )
            return reject({ status: 'error', asset, message: 'Asset Key doesn\'t exist'})

        //console.log(asset)

        indexDB.assets.where({ assetKey: asset.assetKey, projectKey: asset.projectKey }).first( oldAsset => {
            // If the asset doesn't already exist create it, 
            if( oldAsset === undefined ){
                indexDB.assets.put(Object.assign({}, asset, { status: 'saved' })).then(id => {
                    return resolve(indexDB.assets.get(id))
                }).catch( error => {
                    return reject(error)
                })
            } else {
                // Otherwise update the existing one.
                indexDB.assets.update(oldAsset.localKey, { data: asset.data, status: 'saved' }).then( updated => {
                    return resolve(updated)
                }).catch( error => {
                    return reject(error)
                })
            }
        })
    })
}





export const removeProjectAssets = (projectKey) => {
    //console.log('removing')
    // Remove asset from local storage db
    indexDB.assets.where({ projectKey: projectKey }).delete()//then(() => {console.log('delete from store')}, error => { console.log(error)})
    

    // Delete them in the main api
    return api.removeProjectAssets(projectKey)
}





export const removeAssetAndChildren = (assetKey) => {
    indexDB.assets.where({ assetKey: assetKey }).delete()
    
    indexDB.assets.where({ parentKey: assetKey }).delete()

    return api.removeAssetsAndChildren(assetKey)
}




export const deleteAssetsByParent = parentKey => {
    indexDB.assets.where({ parentKey: parentKey }).delete()

    return api.deleteAssetsByParent(parentKey)
}


export const clearAllLocalAssets = () => {
    indexDB.db.assets.clear()
}



export const createAssetInStore = (projectKey, type, status, data, parentKey = null) => {
    let state         = store.getState()
    let newAssetKey   = state.assets.lastKey+1
    store.dispatch(setLastKey(newAssetKey))
    store.dispatch(createAsset(projectKey, newAssetKey, type, status, data, parentKey ))
}