import { 
  SET_STATION_VISIBLE, 
  SET_OPERATOR_WORKBENCH_VISIBLE, 
  SET_STAGE_VISIBLE, 
  SET_ACTIVITY_TIMESTAMP,
  SET_SAVE_ACTIVITY_TIMESTAMP,
  SET_SLEEP_MODE,
  SET_SLEEP_TIMEOUT
} from "../actions/dashboard"

import { dashboardDefaults } from '../defaults'

export default function(state = dashboardDefaults, action) {
  switch (action.type) {
    
   
    case SET_OPERATOR_WORKBENCH_VISIBLE: 
      return Object.assign({}, state, {
        operatorWorkbenchVisible: action.payload.status
      })
    
    
    case SET_STATION_VISIBLE: 
      return Object.assign({}, state, {
        stationVisible: action.payload.status
      })
    
    
    case SET_STAGE_VISIBLE: 
      return Object.assign({}, state, {
        stageVisible: action.payload.status
      })

      
    case SET_ACTIVITY_TIMESTAMP:
      return Object.assign({}, state, {
        lastActionTimestamp: action.payload.timestamp
      })
  
    case SET_SAVE_ACTIVITY_TIMESTAMP:
        return Object.assign({}, state, {
          lastSaveActionTimestamp: action.payload.timestamp
        })
    
    case SET_SLEEP_MODE:
      return Object.assign({}, state, {
        asleep: action.payload.status
      })

    case SET_SLEEP_TIMEOUT: 
      return Object.assign({}, state, {
        sleepTimeout: action.payload.minutes
      })


    default:
      return state;
  }
}
