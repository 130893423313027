import { HYDRATE_ASSETS, CREATE_DEFAULT_PROJECT_ASSETS } from '../actions/loadedAssets'
import { setAssetsLoaded } from '../actions/transients'


const whitelist = [
    HYDRATE_ASSETS,
    CREATE_DEFAULT_PROJECT_ASSETS
]
export const assets = store => next => action => {


    // TODO Detect when an asset type is being changed and try to convert the data in the existing format
    // to the new format

    next(action)
    //  
    if( whitelist.includes(action.type)  )
        store.dispatch(setAssetsLoaded())    

}