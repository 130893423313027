import toaster from "toasted-notes" //https://github.com/bmcmahen/toasted-notes

const defaultOptions = {
    duration: 2000,
    position: 'top-right'
}

export const showMessage = ( message, options = {} ) => {
    let compiledOptions = Object.assign({}, defaultOptions, options)
    return toaster.notify(message, compiledOptions)
}