import { 
    CREATE_DEFAULT_PROJECT_ASSETS, 
    SET_ASSETS_STATUS_TO_SAVED, 
    REMOVE_ASSETS_BY_PARENT,
    HYDRATE_ASSETS,
    UPDATE_ASSET_DATA,
    CREATE_ASSET,
    DELETE_ASSET,
    PIN_ASSET,
    UPDATE_ASSET_TYPE
} from "../actions/loadedAssets"

import {loadedAssetDefaults, assetStructure, loadedAssetDefaultStructure, assetMetaDefaults, projectDataTypes} from '../defaults'


export default function(state = loadedAssetDefaults, action) {

    let newKey     = null
    let items      = null
    let newState   = null 
    let newItems   = null

    switch (action.type) {
    
   
    case CREATE_DEFAULT_PROJECT_ASSETS:
        newKey = action.payload.newAssetKey
        return Object.assign({}, state, {
            scratchpad: { 
                [newKey]:   Object.assign({}, assetStructure, {
                    assetKey:   newKey,
                    projectKey: action.payload.projectKey,
                    type:       'scratchpad', // 'undetermined',
                    status:     'default',
                    data:       projectDataTypes.scratchpad
                })
            },
            term: {},
            termData: {}
        })


    // Also wipes and replaces asset data with default dataset
    case UPDATE_ASSET_TYPE:
        newItems = Object.assign({}, state[action.payload.fromAssetType]) 
        newItems[action.payload.assetKey] = Object.assign({}, state[action.payload.fromAssetType][action.payload.assetKey], {
            type: action.payload.assetType,
            data: projectDataTypes[action.payload.assetType],
            status: 'updated'
        })
        // Clears the object from its old object
        const { [action.payload.assetKey]: value, ...clearedType } = state[action.payload.fromAssetType]

        return Object.assign({}, state, {
            [action.payload.assetType]: {...newItems},
            [action.payload.fromAssetType]: clearedType
        })



        // Any assets that weren't save will now be set to saved
    case SET_ASSETS_STATUS_TO_SAVED:
        newState = {...state}
        action.payload.assets.forEach( asset => {

                if( typeof newState[asset.type] !== 'undefined' && typeof newState[asset.type][asset.assetKey] !== 'undefined' )
                    newState[asset.type][asset.assetKey].status = 'saved'

                // This asset might have been a pinned asset 
                // For example in split view, we get a scratchpad asset from the database but attach it to the store
                // via loadedAssets.pinned, rather than loadedAssets.scratchpad
                // In which case we can't rely on it's specified "type", we need to manually check if its a pinned asset
                if( newState['pinned'] !== undefined && newState['pinned'][asset.assetKey] !== undefined )
                    newState['pinned'][asset.assetKey].status = 'saved'
            
        })
        return newState
    




    case HYDRATE_ASSETS:
        items = JSON.parse(JSON.stringify(loadedAssetDefaultStructure))  //{ scratchpad: {}, term: {}, termData: {}}
        action.payload.assets.forEach( asset => {                 
            items[asset.type][asset.assetKey] = {
                assetKey:   asset.assetKey,
                projectKey: asset.projectKey,
                parentKey:  asset.parentKey,
                status:     asset.status,
                type:       asset.type,
                data:       asset.data            
            }
        })
        return Object.assign({}, items )





    case UPDATE_ASSET_DATA:
        newItems = Object.assign({}, state[action.payload.assetType]) 
        newItems[action.payload.assetKey] = Object.assign({}, state[action.payload.assetType][action.payload.assetKey], {
            data: action.payload.data,
            status: 'updated'
        })
        return Object.assign({}, state, {
            [action.payload.assetType]: {...newItems}
        })

    


    case CREATE_ASSET: 
        newKey = action.payload.newAssetKey
        return Object.assign({}, state, {
            [action.payload.type]: Object.assign({}, state[action.payload.type], { 
                [newKey]:   Object.assign({}, assetStructure, {
                    assetKey:   newKey,
                    projectKey: action.payload.projectKey,
                    parentKey:  action.payload.parentKey,
                    type:       action.payload.type,
                    status:     action.payload.status,
                    data:       action.payload.data
                })
            })    
        })




    case DELETE_ASSET:
        // First delete the main asset
        let rawItems = JSON.parse(JSON.stringify(loadedAssetDefaultStructure))
        // Then remove any assets with this one as a parent
        Object.keys(state).map( assetType => {
            Object.keys(state[assetType]).filter( checkAssetKey => {
                // Filter out items that match our assetKey or have our assetKey as it's parent key
                return parseInt(state[assetType][checkAssetKey].parentKey) !== parseInt(action.payload.projectKey) && parseInt(checkAssetKey) !== parseInt(action.payload.assetKey)
            }).map( assetKey => {
                rawItems[assetType][assetKey] = state[assetType][assetKey]
            })
        })

        return rawItems



    case REMOVE_ASSETS_BY_PARENT:
        newState = JSON.parse(JSON.stringify(loadedAssetDefaultStructure))
        Object.keys(state).map( assetType => {
            Object.keys(state[assetType]).filter( checkAssetKey => {
                // Filter out items that match our assetKey or have our assetKey as it's parent key
                return parseInt(state[assetType][checkAssetKey].parentKey) !== parseInt(action.payload.parentKey) 
            }).map( assetKey => {
                newState[assetType][assetKey] = state[assetType][assetKey]
            })
        })
        return newState



    case PIN_ASSET: 
        //console.log(action.payload.asset, 'trying to pin')
        // Dont pin if the asset id hasn't been created yet,
        // This can happen if the asset hasn't been made in the db
        // before trying to pin it to the split view screen
        if( typeof action.payload.asset === 'undefined')
            return state

        return Object.assign({}, state, {
            pinned: Object.assign({}, state.pinned || {}, { 
                [action.payload.asset.assetKey]:  action.payload.asset
            })    
        })




    default:
        return state;
  }
}
