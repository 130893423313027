//import { SET_ACTIVE_OPERATOR_TAB } from "../actions/operator"
import {stageDefaults} from '../defaults'


export default function(state = stageDefaults, action) {
  switch (action.type) {
    
   
   

    default:
      return state;
  }
}
