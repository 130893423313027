import { SET_ACTIVE_STATION_TAB } from "../actions/station"

import { stationDefaults } from '../defaults'

export default function(state = stationDefaults, action) {
  switch (action.type) {
    
   
    case SET_ACTIVE_STATION_TAB:
      return Object.assign({}, state, {
        activeTab: action.payload.tabKey
      })



    default:
      return state;
  }
}
