import { SET_FOLDER_EXPANDED, ADD_FOLDER, RENAME_FOLDER, UPDATE_FOLDER_PARENT, DELETE_FOLDER } from "../actions/folders"
import {foldersDefaults, folderStructure } from '../defaults'


export default function(state = foldersDefaults, action) {

  switch (action.type) {


    
    case SET_FOLDER_EXPANDED: 
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [action.payload.folderKey]: Object.assign({}, state.items[action.payload.folderKey], {
            expanded: action.payload.expanded
          })
        })
      })
    

      
    case ADD_FOLDER: 
      let newId = state.lastKey +1
      let newFolder = Object.assign({}, folderStructure, {
        id: newId,
        parentFolderKey: action.payload.folderKey
      })
      return Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [newId]: newFolder
        }),
        lastKey: newId
      })
      

    
    case RENAME_FOLDER:
        return Object.assign({}, state, {
          items: Object.assign({}, state.items, {
            [action.payload.folderKey]: Object.assign({}, state.items[action.payload.folderKey], {
              title: action.payload.title
            })
          })
        })


    case UPDATE_FOLDER_PARENT:

      let data = Object.assign({}, state, {
        items: Object.assign({}, state.items, {
          [action.payload.move]: Object.assign({}, state.items[action.payload.move], {
            parentFolderKey: action.payload.to
          })
        })
      })
      return data


    case DELETE_FOLDER:
      let { [action.payload.folderKey]: value, ...newFolderItems } = state.items
      let newFolderState = Object.assign({}, state, {
        items: newFolderItems
      })
      return newFolderState
      

    default:
      return state;
  }
}
