export const CREATE_DEFAULT_PROJECT_ASSETS = 'CREATE_DEFAULT_PROJECT_ASSETS'
export const SET_ASSETS_STATUS_TO_SAVED    = 'SET_ASSETS_STATUS_TO_SAVED'
export const REMOVE_ASSETS_BY_PARENT       = 'REMOVE_ASSETS_BY_PARENT'
export const HYDRATE_ASSETS                = 'HYDRATE_ASSETS'
export const UPDATE_ASSET_DATA             = 'UPDATE_ASSET_DATA'
export const CREATE_ASSET                  = 'CREATE_ASSET'
export const DELETE_ASSET                  = 'DELETE_ASSET'
export const PIN_ASSET                     = 'PIN_ASSET'
export const UPDATE_ASSET_TYPE             = 'UPDATE_ASSET_TYPE'


export const createDefaultProjectAssets = (projectKey, newAssetKey) => {
    return { type: CREATE_DEFAULT_PROJECT_ASSETS, payload: {projectKey, newAssetKey }}
}


export const updateAssetType = (assetKey, fromAssetType, assetType) => {
    return { type: UPDATE_ASSET_TYPE, payload: { assetKey, fromAssetType, assetType } }
}


export const setAssetsStatusToSaved = (assets) => {
    return { type: SET_ASSETS_STATUS_TO_SAVED, payload: { assets }}
}


export const hydrateAssets = (assets) => {
    return { type: HYDRATE_ASSETS, payload: { assets }}
}


export const updateAssetData = (assetKey, assetType, data) => {
    return { type: UPDATE_ASSET_DATA, payload: {assetType, assetKey, data }}
}

export const createAsset = (projectKey, newAssetKey, type = 'blank', status = 'created', data = {}, parentKey = null ) => {
    return { type: CREATE_ASSET, payload: { projectKey, newAssetKey, type, status, data, parentKey }}
}

export const deleteAsset = (assetType, assetKey) => {
    return { type: DELETE_ASSET, payload: { assetType, assetKey }}
}

export const removeAssetsByParent = (parentKey) => {
    return { type: REMOVE_ASSETS_BY_PARENT, payload: { parentKey }}
}

export const pinAsset = (asset) => {
    return { type: PIN_ASSET, payload: { asset }}
}



