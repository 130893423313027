import React, { lazy, Suspense} from 'react'
import Loading from '../dashboard/utils/Loading/Loading'

const DashboardScreen = (props) => {

    const Screen = lazy(() => (
        import('../dashboard/Dashboard')
    ))

    return (
        <Suspense fallback={<Loading />}>
            <Screen />
        </Suspense>
    )
}

export default DashboardScreen