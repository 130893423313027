import React, { useEffect, useState, Suspense, lazy } from 'react'
import Login from '../login/Login'
import Loading from '../dashboard/utils/Loading/Loading'
import './offlineActions.css'


const LoginScreen = props => {

    const [showOfflineActions, setShowOfflineActions] = useState(false)
    const [showComponents, setShowComponents] = useState([])

    const importView = util => {
        return lazy(() =>
            import(`../dashboard/utils/${util}/${util}`)//.catch(() => import(`./views/NullView`))
        )
    }

    const loadView = async () => {
        // TODO if we need to add more components do it as an array here
        const Active = await importView('PurgeCache')
        setShowComponents([Active])
    }



    useEffect(() => {
        showOfflineActions && loadView()
    }, [showOfflineActions])



    return (
        <div id="loginScreen">
            <Login/>
            <button className={`toggleOfflineActions ${showOfflineActions ? 'active' : '' }`} onClick={() => setShowOfflineActions(!showOfflineActions)}>
                <i className="fas fa-bolt" aria-hidden="true" />
            </button>
            { 
                showOfflineActions && showComponents.length > 0 ? 
                <div className="offlineActions">
                    <Suspense fallback={<Loading />}>
                        {showComponents.map( (Component, index) => {
                            return <Component key={`offlinePlugin${index}`} />
                        })}
                    </Suspense>
                </div>
                : null 
            }
        </div>
    )
}

export default LoginScreen