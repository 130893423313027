import Dexie from 'dexie'

const db = new Dexie("stormjournal")
db.version(2).stores({
    assets: 'localKey++,assetKey,projectKey,parentKey,type,status'
})
db.version(3).stores({
    assets: 'localKey++,assetKey,projectKey,parentKey,type,status,[assetKey+projectKey]'
})
export default db

// expose store when run in Cypress
if (window.Cypress) {
    window.indexDB = db
}
