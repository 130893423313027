import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { setProjectStageScrollTop } from '../../../../redux/actions/projects'

import { Subject } from 'rxjs'
import { auditTime } from 'rxjs/operators'

const scrollObserver = new Subject().pipe(
    auditTime(3000)
) 

const ScrollPositionSave = ({ setProjectStageScrollTop, projectKey, initialScrollTop, children, ...attributes }) => {


    const scrollElement   = useRef()

    useEffect(() => {

        scrollElement.current.scrollTop = initialScrollTop

        let scrollSubscription = scrollObserver.subscribe( scrollTopPosition => {
            setProjectStageScrollTop(projectKey, scrollTopPosition)
        })

        return () => {
            scrollSubscription.unsubscribe()
        }

    }, [])
    
 
    const handleScroll = (e) => {
            scrollObserver.next(e.target.scrollTop)
    }


    return (
        <div className={attributes.className} onScroll={handleScroll} ref={scrollElement}>
            { children }
        </div>
    )
}

const mapState = state => {
    return {
        projectKey:      state.loadedProjects.open,
        initialScrollTop: state.projects.items[state.loadedProjects.open].stageScrollTop || 0
    }
}

const mapDispatch = dispatch => {
    return {
        setProjectStageScrollTop: (projectKey, value) => dispatch(setProjectStageScrollTop(projectKey, value))
    }
}

export default connect(mapState, mapDispatch)(ScrollPositionSave)