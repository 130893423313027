import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { startLogin } from '../../services/authService'
import config from '../../config'
import Loading from '../dashboard/utils/Loading/Loading'
import './login.css'
import { hydrate } from '../../redux/actions/root'
import { Redirect } from 'react-router'
import { showMessage } from '../../services/toastService'
import api from '../../services/dataEngine/apiService'

const Login = ({ isLoggedIn, hydrate }) => {


    const [loading, setLoading]         = useState(false)

    const textInput                     = useRef()

    const login = (e) => {
        e.preventDefault()
        setLoading(true)
        startLogin(e.target.email.value, e.target.password.value).then( loggedInUser => {
            // loggedInUser returns an object with saved state + fresh user data
            setLoading(false)
            hydrate(loggedInUser)
        }, error => {
            setLoading(false)
            showMessage('Please check your login details and try again.')
        })
    }


    useEffect(() => {
        if( textInput.current !== undefined )
            textInput.current.focus()
    }, [])

  

    return ( 
        isLoggedIn ? 
            <Redirect to="/" />
        :   
            <div className="loginForm">
                <img src="bolt-icon-enclosed-circle-128.png" alt="StormJournal Logo" className="loginLogo" />
                {
                    loading ? 
                        <div style={{textAlign: 'center'}}>
                            <Loading />
                            <p>Authenticating...</p>
                        </div>
                    : 
                    <div className="inputs">
                        <h5 className="text-center ">Welcome to StormJournal Writing Suite</h5>
                        <form onSubmit={login} className="mb-3">
                            <p>
                                <input name="email" type="email" id="email" ref={textInput} placeholder="email" className="" />
                            </p>
                            <p>
                                <input name="password" type="password" id="password" placeholder="password" className="" />
                            </p>

                            <button type="submit" className="loginButton">It's me, open up!</button> <span className=""><a href={config('PASSWORD_RESET_URL')}>Forgot&nbsp;Password?</a></span>
                        </form>
                        <div className="text-center">Don't have an account? <a href={config('REGISTER_URL')} target="_blank" rel="noopener noreferrer">Register.</a></div>
                    </div>
                } 
            </div>
    )
}

const mapState = state => {
    return {
        isLoggedIn: state.user.in !== null && api.hasCookie() ? true : false
    }
}

const mapDispatch = dispatch => {
    return { hydrate: (userData) => dispatch(hydrate(userData))}
}
export default connect(mapState,mapDispatch)(Login)