import React from 'react'
import './reset.css'
import './App.css'
import 'toasted-notes/src/styles.css'
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom'
import { Provider } from 'react-redux'
import store from '../../redux/store'

import DashboardScreen from '../screens/DashboardScreen'
import LoginScreen from '../screens/LoginScreen'

import PrivateOpenView from '../viewer/PrivateOpenView'


const App = () => {
  return (
    <Provider store={store}>
      <Router>
          <Route exact path="/"             component={DashboardScreen}/>
          <Route exact path="/login"        component={LoginScreen}/>
          <Route path="/v/:projectCryptKey" component={PrivateOpenView} />
      </Router>
    </Provider>
  );
}

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store
}

export default App;
