export const operatorDefaults = {
    tabs: {
        admin: {
            id:            'admin',
            title:         'Admin',
            component:     'Admin',
            componentPath: './tabs/admin/',
            iconType:      'image',
            iconSrc:       'bolt-icon-enclosed-circle-128.png',
            iconClass:     'logo'
        },
        projects: {
            id:            'projects',
            title:         'Projects',
            component:     'Projects',
            componentPath: './tabs/projects/',
            iconType:      'fontawesome',
            iconCode:      'fa-th-list'
        },
        settings: {
            id:            'settings',
            title:         'Settings',
            component:     'Settings',
            componentPath: './tabs/settings/',
            iconType:      'fontawesome',
            iconCode:      'fa-cogs'
        } ,
        sleepmode: {
            id:            'sleepmode',
            title:         'SleepMode',
            component:     'SleepMode',
            componentPath: './buttonComponents/sleepMode/',
            iconType:      'component',
            iconCode:      ''
        } ,
        logout: {
            id:            'logout',
            title:         'logout',
            component:     'Logout',
            componentPath: './buttonComponents/logout/',
            iconType:      'component',
            iconCode:      '',
            iconComponent: ''
        }
    },
    tabOrder: ['admin', 'projects', 'settings', 'sleepmode', 'logout'],
    activeTab: 'projects'
}



export const dashboardDefaults = {
    operatorWorkbenchVisible: true,
    stationVisible:           true,
    stageVisible:             true,
    asleep:                   false,
    sleepTimeout:             20,    // Minutes before going to sleep
    lastActionTimestamp:      Date.now(),
    lastSaveActionTimestamp:  Date.now()
}



export const userDefaults = {
    in: null
}



export const stationDefaults = {
    tabs: {
        terms: {
            id:        'terms',
            title:     'Searcher',
            component: 'TermsStation',
        },
        media: {
            id:        'media',
            title:     'Scrapbook',
            component: 'MediaStation',
        },
        splitView: {
            id:        'splitView',
            title:     'Split View',
            component: 'SplitViewStation',
        },
        /* dynamicFields: {
            id:        'dynamicFields',
            title:     'Dynamic Fields',
            component: 'DynamicFieldsStation',
        }, *//*
        templates: {
            id:        'templates',
            title:     'Templates',
            component: 'TemplateStation',
        }, */
        exporting: {
            id:        'exporting',
            title:     'Export',
            component: 'ExportStation',
        },
        /* stats: {
            id:        'stats',
            title:     'Stats',
            component: 'StatsStation',
        }, */
    },
    //tabOrder: ['terms', 'media', 'splitView', 'dynamicFields', 'templates', 'exporting', 'stats'],
    tabOrder: ['terms', 'media', 'splitView', 'exporting'],
    activeTab: 'terms'
}



export const stageDefaults = {

}


export const folderStructure = {
    id: -1,
    title: 'Untitled Folder',
    parentFolderKey: 0,
    expanded: true,
    optionsVisible: false
}


export const foldersDefaults = {
    items: {
        0: {
            id: 0,
            title: 'root',
            parentFolderKey: -1,
            expanded: true
        },
        1: {
            id: 1,
            title: 'Example Folder',
            parentFolderKey: 0,
            expanded: true
        }
    },
    lastKey: 1
}

export const projectStructure = {
    id: -1,
    title: 'Untitled Project',
    parentFolderKey: 0,
    optionsVisible: false,
    stageScrollTop: 0,
    stageFontMultiplier: 100
}

export const projectsDefaults = {
    items: {
        0: {
            id: 0,
            title: 'Example File',
            parentFolderKey: 1,
            optionsVisible: false,
            stageScrollTop: 0
        }
    },
    lastKey: 0
}


export const projectDataTypes = {
    scratchpad: [{
        type: 'paragraph',
        children: [
          { text: '' }
        ]
    }],
    dataset: {},
    dynamicFields: {
        id: null,
        items: {},
        lastKey: -1
    },
    html: [{
        type: 'paragraph',
        children: [
          { text: '' }
        ]
    }]
}

export const projectFormatTypes = {
    scratchpad: {
        name: 'Text',
        type: 'scratchpad',
        status: 'active',
        icon: 'fas fa-file-alt'
    },
    html: {
        name: 'HTML',
        type: 'html',
        status: 'active',
        icon: 'fas fa-code'
    },
    dynamicFields: {
        name: 'Data Fields',
        type: 'dynamicFields',
        status: 'inactive',
        icon: 'fas fa-columns'
    },
    dataset: {
        name: 'Data Set',
        type: 'dataset',
        status: 'inactive',
        icon: 'fas fa-file-csv'
    }
    // TODO Add markdown
}


export const loadedProjectDefaults = {
    items: [0],
    open: 0
}


export const transientsDefaults = {
    safeToExit:  true,
    assetsLoaded: 0
}

// Types:
// satellites
// scratchpads
// dataset
// searchTerms
// searchTermResults
// templates
// dynamicFields
export const assetStructure = {
    assetKey: -1,
    type: '',
    projectKey: -1,
    parentKey: null,
    data: {},
    status: ''
}

export const assetsDefaults = {
    lastKey: -1
}


export const loadedAssetDefaultStructure = {
    scratchpad: {},
    dataset: {},
    html: {},
    term: {},
    termData: {},
    satelliteRequest: {},
    pinned: {},
    dynamicFields: {},
    undetermined: {}
}

export const loadedAssetDefaults = {
    ...loadedAssetDefaultStructure
}


export const assetMetaDefaults = {
    currentlyExploring: { 0: null },
    currentDynamicFieldAssetKey: {},
    currentSplitViewAssetKey: { },
    //editorFontSize: { 0: 16 }
}


export const hydrateDefault = {
    loadedProjects: loadedProjectDefaults,
    projects:       projectsDefaults,
    folders:        foldersDefaults,
    stage:          stageDefaults, 
    station:        stationDefaults,
    operator:       operatorDefaults,
    dashboard:      dashboardDefaults,
    user:           userDefaults,
    transients:     transientsDefaults,
    assets:         assetsDefaults,
    loadedAssets:   loadedAssetDefaults,
    assetMeta:      assetMetaDefaults
}