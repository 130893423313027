import { SET_ASSETS_LOADED, SET_SAFE_TO_EXIT, SET_TRANSIENT_VARIABLE, REMOVE_TRANSIENT_VARIABLE } from "../actions/transients"
import { transientsDefaults } from '../defaults'

//let deleteProperty = ({[key]: _, ...newObj}, key) => newObj


export default function(state = transientsDefaults, action) {
  switch (action.type) {
    
   
    case SET_ASSETS_LOADED:
      return Object.assign({}, state, {
        assetsLoaded: Date.now()
      })
    
    
    case SET_SAFE_TO_EXIT:
      return Object.assign({}, state, {
        safeToExit: action.payload.status
      })



    case SET_TRANSIENT_VARIABLE:
      return Object.assign({}, state, {
        [action.payload.key]: action.payload.value
      })



    // TODO not sure if this works yet, unused so far
    case REMOVE_TRANSIENT_VARIABLE:
        return state
    //    let newState = deleteProperty(state, action.payload.key)
    //    return newState



    default:
      return state;
  }
}
