import { 
    SET_ASSET_META
} from "../actions/assetMeta"
import {assetMetaDefaults} from '../defaults'


export default function(state = assetMetaDefaults, action) {


    switch (action.type) {
    
   
    case SET_ASSET_META:
        return Object.assign({}, state, {
            [action.payload.key]: action.payload.value
        })


        

    default:
        return state;
  }
}
