import api from './dataEngine/apiService'
import { hydrateDefault } from '../redux/defaults'
import db from './dataEngine/indexDBService'



// This should return a promise that resolves with the user object and their latest 
// state
export const startLogin = (username, password) => {
    return new Promise( (resolve, reject) => {
        // First try for a token.
        api.getToken(username, password).then( tokenResponse => {
            // Save the token to cookie.
            api.setTokenCookie(tokenResponse.data)
            // If that resolves we can get our user
            api.getUserAndState().then( userResponse => {
                // Clear any assets in the local storage, chances are they're out of sync with the 
                // database
                db.assets.clear()
                // This gets hydrated into our state
                resolve(Object.assign({}, hydrateDefault, userResponse.data.state, {
                    user: Object.assign({}, hydrateDefault.user, {
                        in: userResponse.data.user
                    })
                }))
            }, error => {
                reject({ status: 'error', message: 'Couldn\'t find user' })
                api.killAllMemory()
            })
        }, error => {
            reject({ status: 'error', message: 'Login Failed'})
            api.killAllMemory()
        })
    })
}



// Provides a skinny way to just get a new token, without getting all the user data
export const startVerificationLogin = (username, password) => {
    return new Promise( (resolve, reject) => {
        // First try for a token.
        api.getToken(username, password).then( tokenResponse => {
            // Save the token to cookie.
            api.setTokenCookie(tokenResponse.data)
            resolve()
        }, error => {
            reject({ status: 'error', message: 'Login Failed'})
        })
    })
}




export const startLogout = () => {
    // Inform the api we've logged out, only then can we kill the cookie (which is needed for the request itself)
    // if has cookie
    //api.logEvent(store.getState().user.in.id, 'logout').then( response => api.killAllMemory())
    api.killAllMemory()
    
}



export const checkSession = () => {
    return api.checkSession()
}


export const purgeCache = () => {
    window.localStorage.clear()
    return db.assets.clear()
}





